
export const getInfoCupom = (cupom)=>{
  const cupons = [
    `FLYNOW25`,
    `PREMIUM25`,
    `PREMIUM50`,
  ]
  return cupons.includes(cupom)
}

export const getTextCupom = (cupom)=>{
  const cupons = {
    FLYNOW25: '25% de desconto exclusivo para você que é usuário do Flynow 🔥',
    PREMIUM25: '25% de desconto exclusivo para você que é usuário PREMIUM 🔥',
    PREMIUM50: '50% de desconto exclusivo para você que é usuário PREMIUM 🔥',
  }
  return cupons[cupom]
}

export const formatToMoney =(number)=>{
  return number.toFixed(2).replace('.',',')
}

