import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { URL_EMAIL, URL_INSTA_FLYNOW, URL_WHATSAPP } from '../../constants';
import colors from '../../colors';
import { MdMailOutline } from 'react-icons/md';
import { FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'gatsby';
import { getQueries } from '../../utils/query';
export const primary = '#6806AC'
export const background = '#fff'
export const surface = '#f5f5f5'

export const Highlight = styled.span`
  color: ${colors.primary};
`

export function WhyYouNeedThis({ id, title, description, list }) {
  return (
    <Section id={id}>
      <Title style={{ marginBottom: '1rem' }} center>{title}</Title>
      {description}
      {list}
    </Section>
  )
}
export function SectionModel({ id, title, description, list, background, img, primaryColor  }) {
  return (
    <Section id={id} background={background}>
      <TitleSection color={primaryColor ?? primary} style={{ marginBottom: '1rem' }} center>{title}</TitleSection>
      {description}
      {img && (
        <img src={img} height="250" style={{ alignSelf: `center` }} />
      )}
      {list}
    </Section>
  )
}
export function YouWillFindOut({ id, title, list }) {
  const [queries, setQueries] = useState('')
  useEffect(() => {
    setQueries(getQueries())
  }, [])

  return (
    <Section background={primary} id={id}>
      <Title style={{ marginBottom: '1rem' }} color="white" center>{title}</Title>
      {list}
      <Link to={queries + "#buy"} style={{ alignSelf: 'center' }}>
        <Button>Quero garantir esta oportunidade!</Button>
      </Link>
    </Section>
  )
}
export function BuyThisIfYou({ id, title, list, hideCTA }) {
  const [queries, setQueries] = useState('')
  useEffect(() => {
    setQueries(getQueries())
  }, [])

  return (
    <Section id={id}>
      <Title style={{ marginBottom: '1rem' }} center>{title}</Title>
      {list}
      {!hideCTA && (
        <Link to={queries + "#buy"} style={{ alignSelf: 'center' }}>
          <Button>Quero garantir esta oportunidade!</Button>
        </Link>
      )}
    </Section>
  )
}
export function YouWillReceive({ id, title, list, img }) {
  return (
    <Section background={primary} id={id}>
      <Title style={{ marginBottom: '1rem' }} color="white" center> {title} </Title>
      {img && (
        <img src={img} alt={'ebook'} height="300" width="200" style={{ alignSelf: 'center' }} />
      )}
      {list}
    </Section>
  )
}
export function HowMuch({ price, urlCheckout, newPrice, oldPrice, discontPercentage, onClick, buttonStyle, buttonTitle }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: `center`, justifyContent: `center` }}>
      <h1 style={{ marginTop: '1rem', marginBottom: 5 }}>DE <s>R$ {oldPrice}</s></h1>
      {price !== newPrice && (
        <>
          <h2>POR <s>R$ {price}</s></h2>
          <h4 style={{ background: '#dd0000', color: '#fff', textAlign: 'center', margin: 5, padding: 5 }}>
            + {discontPercentage}% OFF
          </h4>
        </>
      )}
      <Row style={{ border: '4px solid ' + primary, alignItems: 'center', color: primary, padding: 7 }}>
        <h5>POR APENAS </h5>
        <h1 style={{ marginLeft: 5 }}> R$ {newPrice} </h1>
      </Row>
      {price !== newPrice && (
        <h4 style={{ background: '#dd0000', color: '#fff', textAlign: 'center', marginTop: 5, padding: 5 }}>
          POR TEMPO LIMITADO!
        </h4>
      )}
      <GoToCheckout urlCheckout={urlCheckout} onClick={onClick}>
        <Button style={{ marginTop: '2rem', ...(buttonStyle||{}) }}>{buttonTitle || 'OBTER EBOOK AGORA!'}</Button>
      </GoToCheckout>
    </div>
  )
}

export function Warranty({ id, background }) {
  return (
    <Section id={id} style={{ alignItems: 'center' }} background={background}>
      <TitleSection color={primary}>Compromisso de satisfação</TitleSection>
      <img src={require("../../assets/images/garantia7dias.png")} style={{ height: 300, width: 300, marginTop: 60 }} alt="garantia" />
      <Description style={{fontFamily: 'Poppins-Bold' }}>7 dias de garantia!</Description>
      <Description>
        Como sabemos que você irá amar nosso guia, te oferecemos uma garantia incondicional de 7 dias.<br />
        Ou seja, caso você esteja insatisfeito(a) com ele, basta nos avisar em até sete dias após a compra que devolvemos seu dinheiro. Simples assim!
      </Description>
    </Section>
  )
}
export function AnyQuestions({ id,background }) {
  const contact = [
    {
      link: URL_WHATSAPP,
      img: <FaWhatsapp className="icon" />,
      description: '31 999107753'
    },
    {
      link: URL_EMAIL,
      img: <MdMailOutline className="icon" />,
      description: 'support@appflynow.com'
    },
    {
      link: URL_INSTA_FLYNOW,
      img: <FaInstagram className="icon" />,
      description: '@appflynow'
    },
  ];
  return (
    <Section id={id} background={background} style={{ alignItems: 'center' }}>
      <TitleSection color={primary} style={{ marginBottom: '2rem' }}>Alguma Dúvida?</TitleSection>
      <ContactContainer>
        {
          contact.map(i => (
            <div className='bonus'>
              <a href={i.link} target='_blank' rel="noopener noreferrer" style={{ textAlign: 'center' }}>
                {i.img}
                <h4 style={{ color: colors.textStrong }}>{i.description}</h4>
              </a>
            </div>
          ))
        }
      </ContactContainer>
    </Section>
  )
}

export function GoToCheckout({ children, urlCheckout }) {
  return <a href={urlCheckout} onClick={() => {
    if (window?.fbq) {
      window.fbq('click_checkout');
      window.fbq('track', 'click_checkout');
      window.fbq('track', 'click_checkout_' + urlCheckout);
    }
  }}>{children}</a>
}

export function MainSection({ title, description, img, textCupom, warning }) {
  const [queries, setQueries] = useState('')
  useEffect(() => {
    setQueries(getQueries())
  }, [])
  return (
    <MainSectionContainer img={img} style={{ minHeight: '97vh' }}>
      <TitleMain center>{title}</TitleMain>
      <Description color="#444" style={{ marginBottom: 10 }}>{description}</Description>
      <img src={img} alt={title} className="main-img-ebook" />
      {textCupom && (
        <>
          <h4 style={{ background: '#dd0000', color: '#fff', textAlign: 'center', padding: 5, marginTop: 15, marginRight: 10 }}>
            {textCupom}
          </h4>
          {/* <h5 style={{background: '#fff', color: '#dd0000', textAlign: 'center',marginTop: 5 }}>
                POR TEMPO LIMITADO!
              </h5> */}
        </>
      )}
      {warning && (
        <>
          <h4 style={{ background: '#dd0000', fontWeight: 'normal', color: '#fff', textAlign: 'center', padding: 5, marginTop: 15, marginRight: 10 }}>
            {warning}
          </h4>
          {/* <h5 style={{background: '#fff', color: '#dd0000', textAlign: 'center',marginTop: 5 }}>
                POR TEMPO LIMITADO!
              </h5> */}
        </>
      )}
      <Link to={queries + '#more'}>
        <Button>QUERO SABER MAIS!</Button>
      </Link>
    </MainSectionContainer>
  )
}

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  .main-img-ebook{
    height: 450px;
    object-fit: contain;
  }
  @media (max-width: 920px) {
    flex-direction: column;
    .main-img-ebook{
      object-fit: contain;

      height: 200px;
      width: 130px;
      margin-top:10px;
      align-self: center;
    }
	}
`
export const Title = styled.h1`
  font-size: 3.5rem;
  color: ${({ color }) => color};
  ${({ center }) => center && 'text-align: center;'}
  @media (max-width: 600px) {
    font-size: 2.2rem;
	}
`
export const TitleMain = styled.h1`
  font-size: 3rem;
  color: ${({ color }) => color};
  ${({ center }) => center && 'text-align: center;'}
  @media (max-width: 600px) {
    font-size: 2.2rem;
	}
`
export const TitleSection = styled.h2`
  font-size: 2.3rem;
  color: ${({ color }) => color};
  ${({ center }) => center && 'text-align: center;'}
  @media (max-width: 600px) {
    font-size: 2rem;
	}
`

export const Description = styled.p`
  margin-top: 1.5rem;
  color: ${({ color }) => color};
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 1.6rem;
`
export const MainSectionContainer = styled.main`
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  padding: 0% 8%;
  background: ${background};
    ul{
    margin-top: 1.5rem;
    font-size: 1.5rem;
    color: #333;
    @media (max-width: 600px) {
    font-size: 1.3rem;
	  }
  }
  .main-img-ebook{
    height: 400px;
    object-fit: contain;
  }
  @media (max-width: 920px) {
    .main-img-ebook{
      height: 200px;
      width: 130px;
      margin-top:10px;
      align-self: center;
    }
	}

`;
export const Button = styled.button`
  padding: 18px;
  border:none;
  background: #27AE60;
  color:white;
  box-shadow: 1px 1px 50px 1px rgba(0,0,0,0.5);
  border-radius:30px;
  width: 350px;
  margin-top: 1.5rem;
  cursor:pointer;
  &:hover{
    opacity: 0.9;
  }
  font-family: 'Poppins-Bold';
  @media (max-width: 600px) {
    padding: 12px;
    font-size: 1rem;
    width: 300px;
	}
  `
export const BonusContainer = styled.h2`
  background: #EB5757;
  color:white;
  text-align: center;
  margin-top: 1.5rem;
  cursor:pointer;
  font-family: 'Poppins-Bold';
  padding: 5px;
  `
export const Section = styled.section`
  display: flex;
  flex-direction: column;
  padding: 5% 8%;
  background: ${({ background }) => background || 'white'};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 60% center;

  ul{
    margin-top: 1.5rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.5rem;
    color: #333;
    @media (max-width: 600px) {
    font-size: 1.3rem;
	  }
  }
`;
export const ContactContainer = styled.div`
a{
		text-decoration: none;
	}
	display: flex;
	flex: 1;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content:space-around;
	.icon {
		font-size: 10rem;
		color: ${colors.textStrong};
		cursor: pointer;
		transition: all 0.4s linear;
	}
	.icon:hover {
		color: ${colors.primary2};
	}
	.bonus{
		align-items:center;
		justify-content:center;
		/* flex-wrap: wrap; */
		min-width:300px;
		color:white;
    margin-top:2rem;
	}
	div{
		display: flex;
		flex: 1;
		flex-direction: column;
		flex-wrap: wrap;
	}
	h3{
		/* text-align:center; */
		color: ${colors.primary};
		margin-top:1.5rem;
	}
	h4{
		margin:0.5rem 1rem;
		color: ${colors.textStrong};
	}
	span{
		margin:0 2rem;
		color: ${colors.textNormal};
	}
	@media (max-width: 600px) {
		flex-direction:column;
		.bonus{
			min-width:330px;
		}
	}
	@media (max-width:400px) {
		.bonus{
			width:300px;
		}
	}
`;
